import { useEffect, useState } from "react";
import SiteBackground from "../background";

const Loading = require("./loading.gif");

const LoadingProvider = ({ children }: any) => {
  const [isLoading, setIsLoading] = useState<Boolean>(true);

  useEffect(() => {
    const handleLoad = () => {
      setIsLoading(false);
    };

    window.addEventListener('load', handleLoad);

    // Cleanup listener on component unmount
    return () => window.removeEventListener('load', handleLoad);
  }, []);

  return (
    <>
      <div className="loading-page-component">
        <div
          style={{
            background: "rgba(0, 0, 0, .3)",
            height: "100vh",
            width: "100vw",
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={Loading} />
        </div>
      </div>

      {children}
    </>
  )
};

export default LoadingProvider;
