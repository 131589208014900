import React, { lazy } from "react";
import ReactDOM from "react-dom/client";

import "./site/global/styles/global.scss";
import LoadingProvider from "./site/components/loading/loading";

const SiteBackground = lazy(() => import("./site/components/background"));

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <LoadingProvider>
      <SiteBackground />
    </LoadingProvider>
  </React.StrictMode>
);
